// order: asc | desc
export const sortByField = (data, field, order = 'asc') => {
  data.sort((a, b) => {
    if (a[field] === b[field]) {
      return 0
    }

    if (a[field] === null) {
      return order === 'asc' ? 1 : -1
    }

    if (b[field] === null) {
      return order === 'asc' ? -1 : 1
    }

    return order === 'asc'
      ? (a[field] < b[field] ? -1 : 1)
      : (a[field] < b[field] ? 1 : -1)
  })
}
