import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ description, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            lang
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const pageTitle = title || site.siteMetadata.title

  return (
    <Helmet htmlAttributes={{ lang: site.siteMetadata.lang }}>
      <title>{pageTitle}</title>

      <meta name='description' content={metaDescription} />
      <meta name='msapplication-TileColor' content='#0049ff' />
      <meta name='theme-color' content='#ffffff' />
      <meta name='application-name' content='Professor Ferretto' />

      <meta property='og:url' content={process.env.GATSBY_SITE_URL} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={pageTitle} />
      <meta property='og:site_name' content='Professor Ferretto' />
      <meta property='og:image' content={`${process.env.GATSBY_SITE_URL}/social.jpg`} />
      <meta property='og:description' content='Tenha uma Preparação Completa em Matemática, Física, Química e Biologia para ENEM e Vestibulares. Seja Aluno da Melhor Plataforma de Estudos Online.' />
      <meta property='og:locale' content='pt_BR' />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@prof_ferretto' />
      <meta name='twitter:url' content={process.env.GATSBY_SITE_URL} />
      <meta name='twitter:title' content={pageTitle} />
      <meta name='twitter:description' content='Tenha uma preparação completa em Matemática, Física, Química e Biologia para ENEM e Vestibulares. Seja Aluno da Melhor Plataforma de Estudos Online.' />
      <meta name='twitter:image' content={`${process.env.GATSBY_SITE_URL}/social.jpg`} />

      <link rel='apple-touch-icon' sizes='180x180' href='/favicons/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicons/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicons/favicon-16x16.png' />
      <link rel='manifest' href='/favicons/site.webmanifest' />
      <link rel='mask-icon' href='/favicons/safari-pinned-tab.svg' color='#5bbad5' />

      <link rel='icon' href='/favicons/favicon.ico' />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: 'pt-BR',
  meta: [],
  description: '',
}

Seo.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export { Seo }
