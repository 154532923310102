import React, { useState } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'

import { ModalPortal, VideoModal } from 'components'

import PLAY_ICON from './play.svg'

export const Video = ({
  pic,
  name,
  description,
  url,
  handleClick,
  nameColor,
  descriptionColor,
  blackDays,
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleOpen = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  return (
    <>
      <Wrapper>
        <Thumb onClick={handleOpen} isBlackDays={blackDays}>
          <img src={pic} alt={name} />
        </Thumb>
        <Name nameColor={nameColor}>{name}</Name>
        <Description
          dangerouslySetInnerHTML={{ __html: description }}
          descriptionColor={descriptionColor}
        />
      </Wrapper>

      {showModal && (
        <ModalPortal>
          <VideoModal url={url} handleClose={handleClose} />
        </ModalPortal>
      )}
    </>
  )
}

Video.propTypes = {
  pic: t.string.isRequired,
  name: t.string.isRequired,
  description: t.string.isRequired,
  url: t.string.isRequired,
  handleClick: t.func,
  nameColor: t.string,
  descriptionColor: t.string,
  blackDays: t.bool,
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const Thumb = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  cursor: pointer;
  transition: transform 0.2s ease;
  border-radius: 1rem;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme, isBlackDays }) => {
      if (isBlackDays) {
        return rgba(theme.blackFridayTheme.textRed, 0.5)
      }
      return rgba(theme.colors.third, 0.8)
    }};
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 6rem;
    height: 6rem;
    position: absolute;
    margin-left: -3rem;
    margin-top: -3rem;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    background: url(${PLAY_ICON}) no-repeat center;
    background-size: 100% auto;
    z-index: 2;
  }

  &:hover {
    transform: scale(1.02);
  }
`

const Name = styled.p`
  font-size: clamp(1.2rem, 5vw, 2rem);
  font-weight: 700;
  color: ${({ theme, nameColor }) => nameColor || theme.colors.primary};
  margin: 3.5rem 0 1.6rem;
`

const Description = styled.p`
  font-size: clamp(1.2rem, 5vw, 2rem);
  color: ${({ theme, descriptionColor }) =>
    descriptionColor || theme.colors.third};
`
