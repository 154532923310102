import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { lighten } from 'polished'
import { Container, Social } from 'components'
import { media, theme } from 'resources'
import { HorizontalDivider as BaseHorizontalDivider } from 'components/UI/HorizontalDivider'

export const Footer = () => {
  const showCookieWarning = () => {
    const cookieModal = document.querySelector('.cc-window')

    if (cookieModal) {
      cookieModal.style.display = 'flex'
      cookieModal.style.opacity = 1
    }
  }

  return (
    <Wrapper>
      <Content>
        <TopContent>
          <Column>
            <Logo>
              <Link to='/'>
                <img src='/logo-white.svg' alt='Professor Ferretto' />
              </Link>
            </Logo>
          </Column>
          <Column>
            <Title>Professor Ferretto</Title>
            <LinkWrapper>
              <Link to='/politica-de-privacidade#termos-de-uso'>
                Termos de uso
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to='/politica-de-privacidade'>Política de privacidade</Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to='/duvidas-frequentes'>Perguntas frequentes</Link>
            </LinkWrapper>
            <LinkWrapper>
              <LinkRaw href='https://blog.professorferretto.com.br'>
                Blog do Ferretto
              </LinkRaw>
            </LinkWrapper>
          </Column>
          <Column>
            <Title>ESCREVA PRA GENTE!</Title>
            <LinkWrapper>
              <LinkRaw
                primarycolor={theme.colors.background}
                href='mailto:contato@professorferretto.com.br'
              >
                contato@professorferretto.com.br
              </LinkRaw>
            </LinkWrapper>
          </Column>
          <Column>
            <Title>Redes sociais</Title>
            <Social />
          </Column>
        </TopContent>
        <HorizontalDivider />
        <BottomContent>
          <BottomInfo>
            &#169; TODOS OS DIREITOS RESERVADOS {new Date().getFullYear()}
          </BottomInfo>
          <BottomInfo>PROFESSOR FERRETTO MATEMÁTICA LTDA</BottomInfo>
          <BottomInfo>CNPJ: 40.424.646/0001-10</BottomInfo>
          <BottomInfo onClick={() => showCookieWarning()}>
            <a href='#!'>PREFERÊNCIAS DE COOKIES</a>
          </BottomInfo>
        </BottomContent>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.text};
  width: 100%;
  padding: 8rem 2rem 5rem;
`

const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  max-width: 120rem;

  ${media.lessThan('md')`
    flex-wrap: wrap;
  `}

  ${media.lessThan('sm')`
    padding-bottom: 8rem;
  `}
`

const TopContent = styled(Container)`
  display: flex;
  align-items: flex-start;

  ${media.lessThan('md')`
    align-items: center;
    flex-direction: column;
  `}
`

const HorizontalDivider = styled(BaseHorizontalDivider)`
  background-color: #2a2a2a;
`

const BottomContent = styled(Container)`
  display: flex;
  justify-content: center;
  gap: 1rem;

  ${media.lessThan('md')`
    flex-direction: column;
    align-items: center;
  `}

  ${media.lessThan('xsm')`
    text-align: center;
  `}
`

const BottomInfo = styled.span`
  color: #5e5e5e;
  font-weight: bold;
  font-size: 1.1em;

  a {
    color: unset;
    text-decoration: none;
  }

  ${media.greaterThan('md')`
    &:not(:last-child)::after {
      content: '/';
      margin-left: 1rem;
    }
  `}
`

const Column = styled.div`
  flex: 1;

  ${media.lessThan('md')`
    flex-basis: 50%;
    padding-bottom: 2rem;
    text-align: center;
  `}
`

const Logo = styled.div`
  max-width: 200px;

  img {
    max-width: 100%;
  }

  ${media.lessThan('sm')`
    margin: 0 auto;
  `}

  ${media.greaterThan('md')`
    margin-top: 15px;
  `}
`

const Title = styled.h5`
  color: ${({ theme }) => theme.colors.muted};
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.6rem 0 2.6rem;

  ${media.lessThan('sm')`
    text-align: center;
  `}
`

const LinkWrapper = styled.div`
  padding: 0 0 1.2rem;

  ${media.lessThan('sm')`
    text-align: center;
  `}
`

const Link = styled((props) => <GatsbyLink {...props} />)`
  text-decoration: none;
  font-size: 1.3rem;
  color: white;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => lighten(0.03, theme.colors.primary)};
  }
`

const LinkRaw = styled.a`
  text-decoration: none;
  font-size: 1.3rem;
  color: white;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => lighten(0.03, theme.colors.primary)};
  }
`
