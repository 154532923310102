import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { Header } from 'components'
import { media } from 'resources'

export const HeaderInternal = ({ title, imagePath }) => {
  return (
    <Wrapper>
      <Header />
      <Content>
        <ContentWrapper>
          <Title hasImage={imagePath !== undefined}>{title}</Title>
          {imagePath && <img src={imagePath} alt='' />}
        </ContentWrapper>
      </Content>
    </Wrapper>
  )
}

HeaderInternal.propTypes = {
  title: t.string.isRequired,
  imagePath: t.string,
}

const Wrapper = styled.header``

const Content = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 461px;

  ${media.lessThan('md')`
    height: auto;
    padding: 5rem 0;
  `}
`

const ContentWrapper = styled.div`
  max-width: 120rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: auto;
    height: 100%;
    padding-top: 4rem;
  }

  ${media.lessThan('md')`
    flex-direction: column-reverse;
    justify-content: center;

    img {
      width: 50%;
      height: auto;
      padding-top: 0;
    }
  `}
`

const Title = styled.h1`
  position: relative;
  font-size: 53px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.background};
  z-index: 1;
  flex: ${({ hasImage }) => (hasImage ? 'inherit' : '1')};

  ${media.lessThan('md')`
    margin-top: -25px;
  `}

  ${media.lessThan('sm')`
    font-size: 2.8rem;
  `}
`
