import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { colors } from 'resources'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  ::-moz-selection {
    background: ${colors.primary};
    color: ${colors.background};
  }

  ::selection {
    background: ${colors.primary};
    color: ${colors.background};
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    cursor: default;
    font-family: 'Gilroy', sans-serif;
    font-variant-ligatures: none;
    color: #000;
    background: #fff;
  }

  h1, h2, h3,
  h4, h5, h6,
  p {
    margin: 0;
  }

  .flex {
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }
  .justify-center {
    justify-content: center;
  }
  .items-center {
    align-items: center;
  }

  .gap-0 {
    gap: 0 !important;
  }
  .gap-05 {
    gap: 0.15rem !important;
  }
  .gap-1 {
    gap: 0.25rem !important;
  }
  .gap-2 {
    gap: 0.5rem !important;
  }
  .gap-3 {
    gap: 1rem !important;
  }
  .gap-4 {
    gap: 1.5rem !important;
  }
  .gap-5 {
    gap: 3rem !important;
  }

  .fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-5 {
    font-size: 1.25rem !important;
  }
  .fs-6 {
    font-size: 1rem !important;
  }

  .text-white {
    color: white;
  }
  .text-black {
    color: black;
  }
`
