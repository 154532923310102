import styled from 'styled-components'
import styledBy from 'styled-by'
import { lighten } from 'polished'

export const Text = styled.p`
  font-size: clamp(1rem, 5vw, 1.8rem);
  line-height: 1.6;
  max-width: 90rem;
  margin: 0 auto;
  color: ${({ theme }) => lighten(theme.lighten, theme.colors.text)};

  ${styledBy('align', {
    default: 'text-align: center;',
    left: 'text-align: left; margin: 0;',
  })}
`

Text.defaultProps = {
  align: 'default',
}
