import React from 'react'
import ReactDOM from 'react-dom'
import t from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'

import { media, useKeypress } from 'resources'

export const VideoModal = ({ url, handleClose }) => {
  useKeypress('Escape', () => {
    handleClose()
  })

  return (
    <Wrapper>
      <Overlay onClick={handleClose} />
      <Modal>
        <Close onClick={handleClose}>×</Close>
        <Content>
          <iframe
            title='video'
            src={`${url}?autoplay=1&hd=1&show_title=1&show_byline=1&show_portrait=0&fullscreen=1`}
            width='100%'
            height='100%'
            frameBorder='0'
            allow='autoplay; fullscreen'
            allowFullScreen
          />
        </Content>
      </Modal>
    </Wrapper>
  )
}

export const ModalPortal = ({ children }) => {
  return (
    ReactDOM.createPortal(children, document.querySelector('body'))
  )
}

VideoModal.propTypes = {
  url: t.string.isRequired,
  handleClose: t.func.isRequired,
}

ModalPortal.propTypes = {
  children: t.node.isRequired,
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  overflow-y: auto;
`

const Overlay = styled.div`
  background-color: ${({ theme }) => rgba(theme.colors.text, 0.6)};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Modal = styled.div`
  width: 100%;
  padding: 0 1.5rem;
  max-width: 90rem;
  animation-name: fade-in-enter;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-duration: .3s;

  ${media.greaterThan('sm')`
    margin: 3rem auto 0;
  `}
`

const Close = styled.button`
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  margin-left: auto;
  display: block;
  padding: 0;
  appearance: none;
  border: none;
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.background};
`

const Content = styled.div`
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0 0 56.25%;

  > iframe {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.text};
  }
`
