import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { media, theme } from 'resources'

export const NavbarExternalLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ isStickyHeader, isBlackFriday }) =>
    isStickyHeader && !isBlackFriday
      ? theme.colors.primary
      : 'white'} !important;
  text-decoration: none;
  font-size: 1.5em;
  padding: 1rem 0;
  border-radius: 0.5rem;
  text-transform: uppercase;

  ${media.lessThan('sm')`
    justify-content: flex-end;
  `}
`

export const NavbarLink = styled((props) => <GatsbyLink {...props} />)`
  display: flex;
  align-items: center;
  color: ${({ $isStickyHeader, $isBlackFriday }) =>
    $isStickyHeader && !$isBlackFriday
      ? theme.colors.primary
      : 'white'} !important;
  text-decoration: none;
  font-size: 1.5em;
  padding: 1rem 0;
  border-radius: 0.5rem;
  text-transform: uppercase;
`
