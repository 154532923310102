import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { Slick } from 'components'

import { useWindowSize, media } from 'resources'

const settingsDefault = {
  dots: true,
  infinite: false,
  speed: 500,
  arrows: false,
  slidesPerRow: 1,
  rows: 3,
}

const settingsDesktop = {
  ...settingsDefault,
  slidesToShow: 4,
  slidesToScroll: 4,
}

const settingsMobile = {
  ...settingsDefault,
  slidesToShow: 2,
  slidesToScroll: 2,
}

export const ContentSlider = ({ items, textColor }) => {
  const { width } = useWindowSize()
  const settings = width > 600 ? settingsDesktop : settingsMobile

  return (
    <Wrapper>
      <Slick settings={settings}>
        {items.map((item, index) => (
          <Item key={index}>
            <Content>
              <Image>
                <img
                  src={item.image}
                  alt={item}
                />
              </Image>
              <Text textColor={textColor}>
                {item.title}
              </Text>
            </Content>
          </Item>
        ))}
      </Slick>
    </Wrapper>
  )
}

ContentSlider.propTypes = {
  items: t.arrayOf(t.object).isRequired,
  textColor: t.string,
}

const Wrapper = styled.div`
  padding-top: 5rem;

  * {
    outline: none;
  }
`

const Item = styled.div`
`

const Content = styled.div`
  min-height: 8rem;
  display: flex;
`

const Image = styled.div`
  width: 5rem;
  height: 4.4rem;
  min-width: 5rem;
  background: ${({ theme }) => theme.colors.third};
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.lessThan('sm')`
    width: 4rem;
    min-width: 4rem;
    height: 3.4rem;
    border-radius: 4px;
  `}
`

const Text = styled.span`
  font-size: 1.6rem;
  line-height: 1.4;
  padding-left: 1.4rem;
  padding-top: 1.2rem;
  color: ${({ textColor }) => textColor || 'unset'};

  ${media.lessThan('sm')`
    font-size: 1.4rem;
    padding-top: .8rem;
  `}
`
