import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

const Option = ({ value, label }) => <option value={value}>{label}</option>

const Select = ({ items, name, color, backgroundColor, ...props }) => {
  return (
    <SelectWrapper color={color}>
      <SelectElement name={name} color={color} backgroundColor={backgroundColor} {...props}>
        {items.map((item) => (
          <Option
            key={item.value}
            label={item.label}
            value={item.value}
          />
        ))}
      </SelectElement>
    </SelectWrapper>
  )
}

Option.propTypes = {
  value: t.string.isRequired,
  label: t.string.isRequired,
}

Select.propTypes = {
  items: t.array.isRequired,
  name: t.string.isRequired,
  color: t.string,
  backgroundColor: t.string,
}

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 2rem;
    top: 50%;
    margin-top: -.8rem;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${({ theme, color }) => color || theme.colors.primary};
  }
`

const SelectElement = styled.select`
  color: ${({ theme, color }) => color || theme.colors.primary};
  font-weight: 600;
  padding: 1.4em 4em 1.4em 1.6em;
  border-radius: ${({ theme }) => theme.radius.md};
  font-size: 1.4rem;
  width: 100%;
  appearance: none;
  background: ${({ backgroundColor }) => backgroundColor || '#fff'};
  box-shadow: 0 0 .4em rgba(0, 0, 0, .2);
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-bottom: 1rem;
  border: 0;
  outline: 0;
  transition: box-shadow .2s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1.4em rgba(0, 0, 0, .4);
  }
`

export { Select }
