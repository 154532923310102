import styled from 'styled-components'
import { lighten } from 'polished'

export const Disclaimer = styled.p`
  text-align: center;
  font-size: clamp(1rem, 5vw, 1.3rem);
  font-weight: 600;
  color: ${({ theme, texctColor }) => lighten(theme.lighten, texctColor || theme.colors.text)};
  padding: 6rem 0;
  line-height: 1.6;
`
