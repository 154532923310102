import React from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import styled from 'styled-components'
import t from 'prop-types'
import { media, theme, useWindowSize } from 'resources'
import { sortByField } from 'resources/helpers/sort-helper'

const SlickDots = ({
  sliderRef,
  slideCount,
  sliderSettings,
  currentSlide,
  accentColor = theme.colors.accent,
  hoverColor = 'white',
  isBlackFriday,
}) => {
  const defaultSlidesToShow = sliderSettings.slidesToShow
  const breakpoints = sliderSettings.responsive

  const { width } = useWindowSize()
  sortByField(breakpoints, 'breakpoint')

  const getCurrentBreakpoint = React.useCallback(
    () => breakpoints.find((b) => width <= b.breakpoint),
    [width, breakpoints]
  )

  const [currentBreakpoint, setCurrentBreakpoint] = React.useState(
    getCurrentBreakpoint()
  )

  const perPage = currentBreakpoint
    ? currentBreakpoint.settings.slidesToShow
    : defaultSlidesToShow
  const pageCount = Math.ceil(slideCount / perPage)
  // const currentPage = Math.ceil(currentSlide / perPage)

  const handleGoToPreviousSlide = () => {
    sliderRef.current?.slickPrev()
  }

  const handleGoToNextSlide = () => {
    sliderRef.current?.slickNext()
  }

  // const handleGoToSlideIndex = (index) => {
  //   const nextIndex = index * perPage
  //   sliderRef.current?.slickGoTo(nextIndex)
  // }

  React.useEffect(() => {
    setCurrentBreakpoint(getCurrentBreakpoint())
  }, [width, getCurrentBreakpoint, currentBreakpoint])

  if (pageCount <= 1) {
    return null
  }

  return (
    <Wrapper>
      <ArrowIconWrapper
        onClick={() => handleGoToPreviousSlide()}
        isBlackFriday={isBlackFriday}
      >
        <MdKeyboardArrowLeft />
      </ArrowIconWrapper>

      {/* <DotsWrapper>
        {[...Array(pageCount).keys()].map((index) => (
          <DotItem
            key={index}
            onClick={() => handleGoToSlideIndex(index)}
            className={index === currentPage ? 'active' : ''}
            accentColor={
              isBlackFriday ? theme.blackFridayTheme.primary : accentColor
            }
            hoverColor={hoverColor}
            isBlackFriday={isBlackFriday}
          >
            &bull;
          </DotItem>
        ))}
      </DotsWrapper> */}

      <ArrowIconWrapper
        onClick={() => handleGoToNextSlide()}
        isBlackFriday={isBlackFriday}
      >
        <MdKeyboardArrowRight />
      </ArrowIconWrapper>
    </Wrapper>
  )
}

SlickDots.propTypes = {
  sliderRef: t.object.isRequired,
  slideCount: t.number.isRequired,
  sliderSettings: t.object.isRequired,
  currentSlide: t.number.isRequired,
  accentColor: t.string,
  hoverColor: t.string,
  isBlackFriday: t.bool,
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: rgb(175, 185, 212, 0.75);
  font-size: 20px;

  ${media.lessThan('md')`
    font-size: 30px;
  `}

  ${media.lessThan('xsm')`
    font-size: 20px;
    gap: 0.5rem;
  `}
`

const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  user-select: none;
  border-radius: 50%;
  transition: background-color 100ms;

  color: ${({ theme, isBlackFriday }) =>
    isBlackFriday ? theme.blackFridayTheme.primary : 'unset'};

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
  }
`

// const DotsWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 1rem;
// `

// const DotItem = styled.span`
//   cursor: pointer;
//   user-select: none;

//   &:hover {
//     color: ${({ hoverColor }) => hoverColor};
//   }

//   &.active {
//     color: ${({ accentColor }) => accentColor};
//   }
// `

export default SlickDots
