import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  xxxsm: '325px',
  xxsm: '380px',
  xsm: '400px',
  sm: '580px',
  smd: '760px',
  md: '900px',
  lg: '1100px',
  lgMin: '1099px',
  xl: '1300px',
  xl2: '1400px',
  xxl: '1600px',
  xxxl: '2500px',
  xg: '4096px',
  xxg: '5120px',
})

export { media }
