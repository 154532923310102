export const faqContent = [
  {
    title: 'O que são e como funcionam as turmas da Plataforma do Professor Ferretto?',
    content: 'As turmas têm matrículas abertas todos os meses. Elas são 100% focadas nos Vestibulares Tradicionais e na prova do ENEM e você pode se inscrever a qualquer momento para estudar com a gente. Todas as turmas do Professor Ferretto têm como maior foco fornecer aos alunos a melhor preparação para Medicina ou qualquer outro curso de seu interesse, como Engenharia, Odonto, Administração, Economia, etc. As turmas possuem todo o conteúdo de que você precisa para passar em qualquer vestibular. Contamos com os melhores professores da internet, além de todas as disciplinas e correção de redação.',
  },
  {
    title: 'Quais os diferenciais da Plataforma do Professor Ferretto?',
    content: 'Nossa plataforma é uma das melhores do Brasil, com cronogramas de estudos de alto rendimento, mais de 17.000 exercícios em vídeo de todas as matérias, Gerador de Simulados, Cronograma de Estudos por Questões (caso você já esteja em um nível avançado e queira estudar dessa forma), 4 créditos de correção de redação por mês, professores de alto nível e com 100% de aprovação pelos alunos, como Professor Ferretto para Matemática, Professor Michel para Química, Professor Boaro para Física e Professor Landim para Biologia. Além disso, você terá acesso a mais de 4.000 videoaulas do básico ao avançado de todas as matérias, apostilas e resumos gráficos de Biologia, Química, Física e Matemática. E um dos grandes diferenciais que temos é o Curso Completo de Discursivas e o Curso de Produtividade e Foco para ser eficiente nos estudos durante a jornada e na hora da prova.',
  },
  {
    title: 'Quais são as formas de pagamento?',
    content: 'As formas de pagamento são: cartão de crédito, boleto bancário e PIX. Você pode parcelar em até 12x sem juros o seu plano ou pode optar por pagar à vista pelos outros meios de pagamento. No momento, não é possível parcelar o pagamento no boleto.',
  },
  {
    title: 'Posso cancelar meu acesso e ter o reembolso do valor pago?',
    content: 'Sim! Se depois de acessar o conteúdo você não ficar satisfeito, é só pedir o seu dinheiro em até 7 dias corridos da compra, que nós o devolveremos sem pestanejar. Sem nenhum ressentimento, continuamos amigos e tudo bem.',
  },
  {
    title: 'Fiz meu pagamento. Como proceder?',
    content: 'Após efetuar o pagamento, deverá aguardar que o mesmo seja confirmado pelo banco. Em caso de pagamento realizado através de cartão, é quase que imediato. No caso do boleto bancário, poderá demorar até 72h para a confirmação. Independente da forma de pagamento, você receberá um e-mail de confirmação com a senha gerada automaticamente para poder ter acesso à plataforma.',
  },
  {
    title: 'Por que estudar com a gente?',
    content: 'Porque temos o melhor custo-benefício do Brasil, os melhores professores da internet e a missão de levar ensino de alta qualidade para todos os estudantes do Brasil que não teriam acesso a este conteúdo em outros lugares. Além de contar com uma equipe altamente qualificada, fazendo atualizações dos conteúdos mensalmente, melhorias na plataforma com inteligência artificial e outros cursos complementares para a sua preparação.',
  },
  {
    title: 'Quais os assuntos que são tratados na plataforma?',
    content: 'São todos os assuntos necessários e cobrados nas provas dos ENEM e Vestibulares Tradicionais. Você pode baixar o Guia completo com todos os conteúdos clicando aqui: <a href="https://conteudo.professorferretto.com.br/conteudo-do-curso-obrigado" target="_blank">https://conteudo.professorferretto.com.br/conteudo-do-curso-obrigado</a>',
  },
]
