import { useState } from 'react'
import { find, propEq, path, pipe, defaultTo, map, prop } from 'ramda'

export const useContent = (data) => {
  const [selectedDiscipline, setSelectedDiscipline] = useState(null)
  const [activeTab, setActiveTab] = useState(0)

  const handleDisciplineClick = (discipline, tabIndex) => {
    setActiveTab(tabIndex)
    setSelectedDiscipline(discipline)
  }

  const selectDiscipline = pipe(
    path(['currentTarget', 'value']),
    setSelectedDiscipline
  )

  return {
    activeTab,
    disciplines: getDisciplines(data),
    classGroups: getClassGroups(data, selectedDiscipline),
    selectDiscipline,
    handleDisciplineClick,
  }
}

const getClassGroups = (data, discipline) => {
  if (!discipline) return getInitialClassGroups(data)
  return getGroupsByDiscipline(data, discipline)
}

const getGroupsByDiscipline = (data, discipline) =>
  pipe(
    prop('classGroupsByDiscipline'),
    find(propEq('discipline', discipline)),
    prop('classGroups')
  )(data)

const getInitialClassGroups = pipe(
  path(['classGroupsByDiscipline', '0', 'classGroups']),
  defaultTo([])
)

const getDisciplines = pipe(
  prop('classGroupsByDiscipline'),
  defaultTo([]),
  map(({ discipline }) => ({
    label: discipline,
    value: discipline,
  }))
)
