import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import Typed from 'react-typed'

import { Container, Title, Slick } from 'components'
import { media, useWindowSize } from 'resources'

const typedOptions = {
  strings: [
    'Matemática',
    'Ciências da Natureza',
    'Ciências Humanas',
    'Linguagens',
    'Redação',
  ],
  typeSpeed: 40,
  backSpeed: 50,
  backDelay: 1000,
  loop: true,
  className: 'typed-text',
}

const settingsDefault = {
  dots: true,
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 2,
}

const settingsDesktop = {
  ...settingsDefault,
  slidesToShow: 6,
  slidesToScroll: 6,
}

const settingsMobile = {
  ...settingsDefault,
  slidesToShow: 2,
  slidesToScroll: 2,
}

export const About = () => {
  const { width } = useWindowSize()
  const settings = width > 600 ? settingsDesktop : settingsMobile

  return (
    <Wrapper>
      <Container>
        <Title size='sm' color='blue'>
          A melhor plataforma com o melhor custo-benefício do mercado
        </Title>
        <Title as='h3'>
          Aulas de <TextLoopWrapper><Typed {...typedOptions} /></TextLoopWrapper>
          <TitleTarget>para Enem e Vestibulares</TitleTarget>
        </Title>

        <Features>
          <Title size='sm' color='blue'>
            Diferenciais da Plataforma
          </Title>

          <GoalsWrapper>
            <Slick settings={settings}>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/basico-ao-avancado.svg' alt='Domine do básico ao avançado' />
                  </GoalsIcon>
                  <GoalsTitle>Domine do <strong>básico ao avançado</strong></GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/questoes-video.svg' alt='Quesões em video' />
                  </GoalsIcon>
                  <GoalsTitle>Milhares de Questões Resolvidas <strong>em vídeo</strong></GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/aulas-online.svg' alt='Curso com aulas 100% online' />
                  </GoalsIcon>
                  <GoalsTitle>Curso <strong>100% online</strong></GoalsTitle>
                </GoalsItem>
              </div>
              {/* <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/aplicativo.svg' alt='Aplicativo da plataforma' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Aplicativo</strong> da Plataforma</GoalsTitle>
                </GoalsItem>
              </div> */}
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/estatisticas-individuais.svg' alt='Estatísticas individuais' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Estatísticas</strong> individuais</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/ranking-geral.svg' alt='Ranking Geral por Curso' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Ranking</strong> geral por curso</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/simulado.svg' alt='Simulados semanais' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Simulados</strong> semanais</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/planos-de-estudo.svg' alt='Planos de Estudos Pré-Definidos' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Planos de estudos</strong> pré-definidos</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/aulas-aprofundamento.svg' alt='Aulas de aprofundamento' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Aulas</strong> de aprofundamento</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/discursivas.svg' alt='Curso de Discursivas' />

                  </GoalsIcon>
                  <GoalsTitle>Curso de <strong>discursivas</strong></GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/exercicios.svg' alt='Exercicios' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Exercícios</strong> de manipulação</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/facilitacoes-graficas.svg' alt='Resumos gráficos por aula' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Resumos gráficos</strong> por aula</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/apostilas.svg' alt='Apostilas' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Apostilas</strong> para reforço</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/material-download.svg' alt='Materiais de apoio' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Materiais de apoio</strong> para download</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/auloes.svg' alt='Aulões' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Aulões exclusivos</strong> para assinantes</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/comunidade.svg' alt='Exercicios' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Comunidade exclusiva</strong> para todos os alunos</GoalsTitle>
                </GoalsItem>
              </div>
              <div>
                <GoalsItem>
                  <GoalsIcon>
                    <img src='/about-features/exercicios-pdf.svg' alt='Exercicios em PDF' />
                  </GoalsIcon>
                  <GoalsTitle><strong>Exercícios em PDF</strong> para todos os alunos</GoalsTitle>
                </GoalsItem>
              </div>
            </Slick>
          </GoalsWrapper>

        </Features>

      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 6rem 0 10rem;

  ${media.greaterThan('lg')`
    padding: 8rem 0 11rem;
  `}
`

const Features = styled.div`
  padding-top: 10rem;
`

const TextLoopWrapper = styled.span`
  color: ${({ theme }) => theme.colors.primary};

  .typed-cursor {
    font-weight: normal;
  }

  ${media.lessThan('sm')`
    display: block;
  `}
`

const TitleTarget = styled.span`
  display: block;
`

const GoalsWrapper = styled.div`
  position: relative;
  max-width: 1100px;
  margin: 3rem auto;

  ${media.lessThan('md')`
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: block;
      width: 10px;
      background: #fff;
      box-shadow: -7px 0 8px 5px #fff;
    }
  `}
`

const GoalsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 15%;
  margin-bottom: 2rem;

  ${media.lessThan('md')`
    flex-basis: auto;
    width: 20rem;
    padding: 0 2rem 0 1.6rem;

    &:last-child {
      padding-right: 2.4rem;
    }
  `}
`

const GoalsIcon = styled.div`
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('sm')`
    width: 8rem;
    height: 8rem;
  `}

  img {
    width: auto;
    height: 4rem;

    ${media.lessThan('sm')`
      height: 3.4rem;
    `}
  }
`

const GoalsTitle = styled.h3`
  margin: 0;
  padding: 1rem 0 0;
  font-size: 1.5rem;
  text-align: center;
  font-weight: normal;
  line-height: 1.6;
  color: ${({ theme }) => lighten(theme.lighten, theme.colors.text)};

  strong {
    text-transform: uppercase;
    display: block;
    color: ${({ theme }) => theme.colors.text};
  }

  ${media.lessThan('sm')`
    font-size: 1.4rem;
  `}
`
