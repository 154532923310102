import React from 'react'
import t from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from 'resources'
import './layout.css'

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: t.node.isRequired,
}

export { Layout }
