import React, { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import { Container as BaseContainer, Title } from 'components'
import { media, theme } from 'resources'
import { TestimonialCard } from './testimonial-card'
import t from 'prop-types'
import { SUBSCRIBE_URL } from 'resources/constants'
import SlickDots from 'components/teachers/components/SlickDots'
import Slider from 'react-slick'
import BlackFridayContext from 'resources/contexts/BlackFridayContext'
import { sortByField } from 'resources/helpers/sort-helper'

export const Testimonials = ({ testimonials: testimonialsContent, utm }) => {
  const isBlackFriday = useContext(BlackFridayContext)
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next)
    },
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  sortByField(testimonialsContent, 'viewOrder')

  return (
    <Box isBlackFriday={isBlackFriday}>
      <Container>
        <Wrapper>
          <Title size='sm' color={isBlackFriday ? 'blackFriday' : 'blue'}>
            Conheça os milhares de alunos que
            <br />
            conquistaram a sua aprovação com apoio
            <br />
            da Plataforma Professor Ferretto
          </Title>

          <Content>
            <Slider ref={sliderRef} {...settings}>
              {testimonialsContent.map((data, index) => (
                <TestimonialWrapper key={index}>
                  <TestimonialCard
                    isBlackFriday={isBlackFriday}
                    data={data}
                    isHomePage
                    equalHeights
                  />
                </TestimonialWrapper>
              ))}
            </Slider>
            <SlickDots
              sliderRef={sliderRef}
              slideCount={testimonialsContent.length}
              sliderSettings={settings}
              currentSlide={currentSlide}
              accentColor={theme.colors.primary}
              hoverColor={theme.colors.primary}
              isBlackFriday={isBlackFriday}
            />
          </Content>

          <ConversionButtonWrapper>
            <ConversionButton
              href={`${SUBSCRIBE_URL}${utm ? utm.replace(/\?/g, '') : ''
              }&field_action=comece_agora_dep`}
              isBlackFriday={isBlackFriday}
            >
              COMECE AGORA
            </ConversionButton>
          </ConversionButtonWrapper>
        </Wrapper>
      </Container>
    </Box>
  )
}

Testimonials.propTypes = {
  testimonials: t.array.isRequired,
  utm: t.string,
}

const Box = styled.section`
  background: ${({ isBlackFriday }) =>
  isBlackFriday ? theme.blackFridayTheme.background : '#f7f7f7'};
`

const Container = styled(BaseContainer)`
  ${media.lessThan('xsm')`
    padding: 0;
  `}
`

const Wrapper = styled.div`
  max-width: 120rem;
  width: 100%;
  margin: 0 auto;
  padding: 10rem 1.5rem;

  ${media.lessThan('md')`
    padding: 5rem 1rem;
  `}
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${media.lessThan('md')`
    gap: 3rem;
    padding-top: 2rem;
  `}
`

const TestimonialWrapper = styled.div`
  padding: 0 1rem;
  height: 100%;
`

const ConversionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;

  ${media.lessThan('md')`
    margin-top: 3rem;
  `}
`

const ConversionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 38px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  padding: 1.25rem 6rem;
  width: fit-content;
  transition: all 200ms;

  color: ${({ theme, isBlackFriday }) =>
  isBlackFriday ? theme.blackFridayTheme.textLight : 'white'};

  background: ${({ theme, isBlackFriday }) =>
  isBlackFriday
    ? theme.blackFridayTheme.backgroundPurple
    : theme.colors.primary};

  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  ${media.lessThan('sm')`
    padding: 1.25rem 3rem;
  `}
`
