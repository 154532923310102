import React, { useContext } from 'react'
import styled from 'styled-components'

import { Container as BaseContainer, Title as BaseTitle } from 'components'
import { media, theme } from 'resources'
import { SubscribeNowButton } from 'components/UI/SubscribeNowButton'
import BlackFridayContext from 'resources/contexts/BlackFridayContext'
import t from 'prop-types'

const goals = [
  {
    icon: 'enem.svg',
    alt: 'Enem',
    label: 'Detonar no Enem',
  },
  {
    icon: 'vestibular.svg',
    alt: 'Vestibular',
    label: 'Prestar Vestibular',
  },
  {
    icon: 'federal.svg',
    alt: 'Universidade Federal',
    label: 'Passar nas Universidades Públicas',
  },
  {
    icon: 'federal.svg',
    alt: 'Universidade Particular',
    label: 'Passar nas Universidades Particulares',
  },
  {
    icon: 'ensino-medio.svg',
    alt: 'Ensino médio',
    label: 'Reforço no Ensino médio',
  },
]

export const Goals = ({ utm }) => {
  const isBlackFriday = useContext(BlackFridayContext)

  return (
    <Container isBlackFriday={isBlackFriday}>
      <Wrapper>
        <LeftSide>
          <Title size='sm' color='white' style={{ fontWeight: 'normal' }}>
            A Plataforma do Professor
            <br />
            Ferretto <b>é ideal para você que quer</b>
          </Title>

          <GoalsGrid>
            {goals.map((goal, goalIndex) => (
              <GoalsItem key={goalIndex}>
                <GoalsIcon isBlackFriday={isBlackFriday}>
                  <img
                    src={
                      isBlackFriday
                        ? `/objetivos/black-friday/${goal.icon}`
                        : `/objetivos/${goal.icon}`
                    }
                    alt={goal.alt}
                  />
                </GoalsIcon>
                <GoalsTitle>{goal.label}</GoalsTitle>
              </GoalsItem>
            ))}
          </GoalsGrid>

          <SubscribeNowButtonWrapper>
            <SubscribeNowButton
              backgroundColor={
                isBlackFriday
                  ? theme.blackFridayTheme.backgroundPurple
                  : theme.colors.accent
              }
              textColor={
                isBlackFriday
                  ? theme.blackFridayTheme.textLight
                  : theme.colors.primary
              }
              utm={utm}
            />
          </SubscribeNowButtonWrapper>
        </LeftSide>

        <RightSide>
          <StudentsPictureWrapper>
            <img src='student-couple.png' alt='Um estudante e uma médica' />
          </StudentsPictureWrapper>
        </RightSide>
      </Wrapper>
    </Container>
  )
}

const Container = styled(BaseContainer)`
  max-width: 100%;
  max-height: 510px;
  padding: 5rem 3rem;

  background: rgb(0, 45, 154);

  background: ${({ isBlackFriday, theme }) => {
    if (isBlackFriday) {
      return `linear-gradient(to right,${theme.blackFridayTheme.background}  15%, ${theme.colors.primary} 108%)`
    }

    return `
      linear-gradient(
        20deg,
        rgba(0, 45, 154, 1) 0%,
        rgba(1, 74, 255, 1) 100%
      )
    `
  }};

  ${media.lessThan('md')`
    max-height: fit-content;
  `}
`

const Wrapper = styled(BaseContainer)`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('md')`
    flex-wrap: wrap;
    justify-content: center;
  `}
`

const LeftSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4rem;

  ${media.between('md', 'lg')`
    gap: 1rem;
  `}
`

const RightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  ${media.lessThan('md')`
    justify-content: center;
  `}
`

const StudentsPictureWrapper = styled.div`
  margin-bottom: -53px;

  img {
    height: 540px;
    margin-top: -81px;
  }

  ${media.lessThan('md')`
    img {
      height: 300px;
      margin-top: 2rem;
    }
  `}

  ${media.lessThan('xsm')`
    img {
      height: 260px;
      margin-top: 2rem;
    }
  `}
`

const GoalsGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);

  ${media.lessThan('md')`
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`

const GoalsItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
`

const GoalsIcon = styled.div`
  border-radius: 50%;
  min-width: 5rem;
  min-height: 5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme, isBlackFriday }) =>
  isBlackFriday ? theme.blackFridayTheme.background : theme.colors.primary};

  ${media.lessThan('md')`
    width: 2rem;
    height: 2rem;
  `}

  img {
    width: auto;
    height: 50%;
    /* box-shadow: ${({ theme, isBlackFriday }) =>
    isBlackFriday
      ? `0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon}`
      : 'none'}; */
  }
  box-shadow: ${({ theme, isBlackFriday }) =>
    isBlackFriday
      ? `0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon},
         0 0 10px ${theme.blackFridayTheme.neon}`
      : 'none'};
`

const GoalsTitle = styled.h3`
  margin: 0;
  padding: 1rem 0 0;
  font-size: 1.5rem;
  text-align: start;
  font-weight: normal;
  line-height: 1.6;
  color: white;

  strong {
    text-transform: uppercase;
    display: block;
    color: ${({ theme }) => theme.colors.text};
    letter-spacing: 0.03em;
  }

  ${media.lessThan('sm')`
    font-size: 1.4rem;
  `}
`

const Title = styled(BaseTitle)`
  text-align: left;

  ${media.lessThan('md')`
    text-align: center;
  `}
`

const SubscribeNowButtonWrapper = styled.div`
  display: flex;

  ${media.lessThan('md')`
    justify-content: center;
  `}
`

Goals.propTypes = {
  utm: t.string,
}
