import React from 'react'
import styled, { css } from 'styled-components'
import t from 'prop-types'
import { media } from 'resources'

import ICON_FACEBOOK from './icon-facebook.svg'
import ICON_INSTAGRAM from './icon-instagram.svg'
import ICON_TWITTER from './icon-twitter.svg'
import ICON_TELEGRAM from './icon-telegram.svg'
import ICON_YOUTUBE from './icon-youtube.svg'

const socialNetworks = {
  facebook: ICON_FACEBOOK,
  instagram: ICON_INSTAGRAM,
  twitter: ICON_TWITTER,
  telegram: ICON_TELEGRAM,
  youtube: ICON_YOUTUBE,
}

const items = [
  {
    kind: 'facebook',
    url: 'https://www.facebook.com/ProfessorFerretto',
  },
  {
    kind: 'instagram',
    url: 'https://www.instagram.com/professorferretto',
  },
  {
    kind: 'youtube',
    url: 'https://youtube.com/professorferretto',
  },
  {
    kind: 'twitter',
    url: 'https://twitter.com/prof_ferretto',
  },
  {
    kind: 'telegram',
    url: 'https://t.me/professorferretto',
  },
]

export const Social = ({ iconSize, align }) => {
  return (
    <List align={align}>
      {items.map((item, itemIndex) => (
        <Item key={itemIndex}>
          <Link
            kind={item.kind}
            href={item.url}
            target='_blank'
            iconSize={iconSize}
          >
            {item.kind.toUpperCase()}
          </Link>
        </Item>
      ))}
    </List>
  )
}

Social.propTypes = {
  iconSize: t.number,
  align: t.string,
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  ${media.lessThan('sm')`
    justify-content: ${({ align }) => align || 'center'};
  `}
`

const Item = styled.li`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`

const Link = styled.a`
  display: block;
  width: ${({ iconSize }) => (iconSize ? `${iconSize}rem` : '3.2rem')};
  height: ${({ iconSize }) => (iconSize ? `${iconSize}rem` : '3.2rem')};
  border-radius: 0.8rem;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999rem;
  font-size: 0;

  ${({ kind }) => css`
    background-image: url(${socialNetworks[kind]});
  `}
`
