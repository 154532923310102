import React from 'react'
import styled, { css } from 'styled-components'
import { darken, rgba } from 'polished'
import styledBy from 'styled-by'
import { Link } from 'gatsby'

import { colors, radius, media } from 'resources'

const ghostButton = css`
  border: .1rem solid ${colors.primary};
  background-color: transparent;
  color: ${colors.primary};

  &:hover,
  &:focus {
    color: ${colors.background};
    background-color: ${colors.primary};
  }
`

const baseButton = color => css`
  position: relative;
  z-index: 1;
  background: linear-gradient(152deg, ${color}, ${darken(0.1, color)});
  color: ${colors.background};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${radius.md};
    background: linear-gradient(152deg, ${darken(0.05, color)}, ${darken(0.1, color)});
    transition: opacity 0.2s linear 0s;
    opacity: 0;
    z-index: -1;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 1;
    }
  }
`

const defaultButton = baseButton(colors.primary)
const successButton = baseButton(colors.success)

export const Button = styled(({ shadow, to, href, children, ...props }) => {
  const Component = href ? 'a' : Link
  return <Component href={href} to={to} {...props}>{children}</Component>
})`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  transition: all .25s ease;
  border-radius: ${({ theme }) => theme.radius.xl};
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 1em 1.6em;

  ${styledBy('size', {
    default: '',
    large: 'font-size: 1.6rem;',
    full: 'width: 100%;',
    largefull: 'font-size: 1.5rem; width: 100%;',
  })}

  ${styledBy('variant', {
    default: () => defaultButton,
    ghost: () => ghostButton,
    success: () => successButton,
  })}

  ${({ shadow, theme }) => shadow && css`
    box-shadow: 0 .6rem 1.8rem 0 ${rgba(theme.colors.primary, 0.4)};
  `}

  ${media.lessThan('sm')`
    font-size: 1.4rem;
    min-height: 4rem;

    ${styledBy('size', {
      default: 'padding: 1.2rem 2.4rem;',
      large: 'padding: 1.6rem 9.2rem;',
    })}
  `}
`
