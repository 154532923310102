import React from 'react'
import styled from 'styled-components'

import IMG_SITE from './images/Cronometro-Desktop.png'
import IMG_MOBILE from './images/Cronometro-mobile.png'
import { media } from 'resources'
import { useCountdown } from './hooks/useCountdown'

export const FooterBanner = () => {
  const countDownEndDate = new Date('2024-01-02T02:59:59.000Z')

  const dateTimeAfterOneDays = countDownEndDate.getTime()
  const [days, hours, minutes, seconds] = useCountdown(dateTimeAfterOneDays)
  return (
    <Parent>
      <Link href='https://www.professorferretto.com.br/?utm_source=site&utm_medium=contador-site&utm_campaign=promo-natal-23&utm_content=contador-banner-site&conversion=promo-natal-23' alt='Ver promoção'>
        <Days>{('00' + days).slice(-2)}</Days>
        <Hours>{('00' + hours).slice(-2)}</Hours>
        <Minutes>{('00' + minutes).slice(-2)}</Minutes>
        <Seconds>{('00' + seconds).slice(-2)}</Seconds>
        <Img />
      </Link>
    </Parent>
  )
}

const Parent = styled.div`
 position: relative;
`
const Box = styled(Parent)`
  position: absolute;
  /* width: calc(1.6vw + 10px); height: calc(1.6vw + 10px); */
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  color:#fff;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: clamp( 1rem,3vw,6vh);
  font-weight: 700;
  letter-spacing: 5.5px;
  z-index: 99;
  bottom: 0px;
  ${media.lessThan('sm')`
    font-size: clamp( 4rem,5vw,8vh);
  `}
`

const Days = styled(Box)`
    position: fixed;
    top: 88%;
    left: 55%;
    ${media.lessThan('sm')`
      font-size: clamp( 4rem,5vw,8vh);
    top: 83%;
    left: 13%;
    `}
    ${media.between('xxxl', 'xxg')`
    left: 55.5%;
    `}
    ${media.between('xxl', 'xxxl')`
      left: 55.7%;
    `}
    ${media.between('xl', 'xl2')`
      left: 55.3%;
    `}
    ${media.between('xl2', 'xxl')`
      left: 55.5%;
    `}
`

const Hours = styled(Box)`
    position: fixed;
    top: 88%;
    left: 62%;
    ${media.lessThan('sm')`
      font-size: clamp( 4rem,5vw,8vh);
    top: 83%;
    left: 34%;
    `}
    ${media.between('xxxl', 'xxg')`
    left: 62.5%;
    `}
    ${media.between('xxl', 'xxxl')`
      left: 62.5%;
    `}
    ${media.between('xl', 'xl2')`
      left: 62.5%;
    `}
    ${media.between('xl2', 'xxl')`
      left: 62.5%;
    `}
`

const Minutes = styled(Box)`
    position: fixed;
    top: 88%;
    left: 69%;
    ${media.lessThan('sm')`
      font-size: clamp( 4rem,5vw,8vh);
    top: 83%;
    left: 57%;
    `}
    ${media.between('xxxl', 'xxg')`
    left: 69%;
    `}
    ${media.between('xxl', 'xxxl')`
      left: 69%;
    `}
    ${media.between('xl', 'xl2')`
      left: 69.5%;
    `}
    ${media.between('xl2', 'xxl')`
      left: 69.3%;
    `}
`

const Seconds = styled(Box)`
    position: fixed;
    top: 88%;
    left: 75%;
    ${media.lessThan('sm')`
      font-size: clamp( 4rem,5vw,8vh);
    top: 83%;
    left: 78%;
    `}
    ${media.between('xxxl', 'xxg')`
    left: 76%;
    `}
    ${media.between('xxl', 'xxxl')`
      left: 75.7%;
    `}
    ${media.between('xl', 'xl2')`
      left: 75.9%;
    `}
    ${media.between('xl2', 'xxl')`
      left: 75.9%;
    `}
`

const Img = styled.div`
  background: url(${IMG_SITE});
  bottom: 0px;
  width:100%;
  height: 15%;
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
  position: fixed;
   ${media.lessThan('sm')`
    background: url(${IMG_MOBILE}) center no-repeat;
    background-size: 100% 100%;
  `}
`
const Link = styled.a`
   position: relative;
 `
