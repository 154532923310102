import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { Container, Title, Disclaimer, Select, ContentSlider } from 'components'
import { useWindowSize, media } from 'resources'

import { useContent } from './use-content'

export const Content = (props) => {
  const { width } = useWindowSize()
  const isMobile = width < 600

  const {
    disciplines = [],
    classGroups = [],
    selectDiscipline,
    handleDisciplineClick,
    activeTab,
  } = useContent(props)

  const showData = disciplines.length > 0 && classGroups.length > 0

  return (
    <Container>
      <Wrapper>
        <Title size='sm' color='blue'>Conteúdo do curso</Title>
        <Title as='h3'>O que você vai aprender?</Title>

        {!isMobile &&
          <Tab>
            {disciplines.map(({ label }, index) => (
              <TabItem key={index} onClick={() => handleDisciplineClick(label, index)} active={index === activeTab}>{label}</TabItem>
            ))}
          </Tab>}

        {showData && (
          <>
            {isMobile &&
              <SelectBox>
                <Select
                  name='disciplines'
                  onChange={selectDiscipline}
                  items={disciplines}
                />
              </SelectBox>}

            <ContentSlider items={classGroups} />
          </>
        )}

        <WhiteDisclaimer>
          <span>Quer conhecer o conteúdo completo do curso?</span>
          <Link href='https://conteudo.professorferretto.com.br/conteudo-do-curso'>Baixe aqui</Link>
        </WhiteDisclaimer>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.section`
  border-top: 2px solid ${({ theme }) => theme.colors.secondary};
  padding: 6rem 2rem 0;
  margin-bottom: 5rem;

  ${media.greaterThan('md')`
    padding: 9rem 10rem 0;
  `}
`

const Link = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primary};
  font-size: inherit;
  font-weight: 600;
  border: 1px solid currentColor;
  padding: .4em 1.4em;
  border-radius: 2rem;
  text-decoration: none;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.background};
  }
`

const WhiteDisclaimer = styled(Disclaimer)`
  span {
    display: block;
    max-width: 80%;
    padding-bottom: 1.5rem;
    margin: 0 auto;
    font-size: 1.5rem;
  }
`

const SelectBox = styled.div`
  display: flex;
  justify-content: center;
`

const Tab = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 2rem 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 750px;
`

const TabItem = styled.li`
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  padding: .6em 1em;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  border-radius: 10px;
  margin: 1rem .5rem;
  white-space: nowrap;

  ${({ active }) => !active && css`
    &:hover {
      background: ${({ theme }) => rgba(theme.colors.text, 0.03)};
    }
  `}

  ${({ active }) => active && css`
    color: ${({ theme }) => theme.colors.background};
    background: ${({ theme }) => theme.colors.primary};

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 3rem;
      height: 3rem;
      top: 100%;
      left: 50%;
      margin-left: -1.5rem;
      margin-top: -3rem;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 4px;
      transform: rotate(45deg);
      z-index: -1;
    }
  `}
`
