import React from 'react'
import styled from 'styled-components'
import { Button as BaseButton } from 'components'
import { SUBSCRIBE_URL } from 'resources/constants'
import t from 'prop-types'
import { media } from 'resources'

export const SubscribeNowButton = ({
  customUrl,
  backgroundColor,
  textColor,
  textContent = 'ASSINE AGORA',
  utm,
}) => {
  return (
    <Button
      href={customUrl || `${SUBSCRIBE_URL}${utm ? utm.replace(/\?/g, '') : ''}&field_action=assine_banner`}
      size='large'
      $backgroundColor={backgroundColor}
      $textColor={textColor}
    >
      {textContent}
    </Button>
  )
}

const Button = styled(BaseButton)`
  color: ${({ theme, $textColor }) => $textColor || theme.colors.primary};

  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor || theme.colors.accent};

  width: fit-content;
  padding: 1.75rem 8rem;

  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  ${media.lessThan('sm')`
    padding: 1.75rem 6rem;
  `}
`

SubscribeNowButton.propTypes = {
  customUrl: t.string,
  backgroundColor: t.string,
  textColor: t.string,
  textContent: t.string,
  utm: t.string,
}
