import React from 'react'
import t from 'prop-types'
import Slider from 'react-slick'

import './slick-theme.css'
import './slick.css'

const defaultSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export const Slick = ({ settings = defaultSettings, children }) => {
  return (
    <Slider {...settings}>
      {children}
    </Slider>
  )
}

Slick.propTypes = {
  settings: t.object,
  children: t.node.isRequired,
}
