import React, { useContext } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { Container as BaseContainer, Title, PlanCard } from 'components'
import { media, theme } from 'resources'
import BlackFridayContext from 'resources/contexts/BlackFridayContext'

const titleItems = [
  'Curso de Medicina',
  'Todos os demais Cursos',
  'Prova do ENEM e Todos os Vestibulares do Brasil',
]

export const Plans = ({ plans, utm }) => {
  const isBlackFriday = useContext(BlackFridayContext)
  const accentColor = isBlackFriday
    ? theme.colors.blackFridayAccent
    : theme.colors.accent

  if (plans) {
    plans.map((plan) => {
      if (plan.hasEssay === null) {
        plan.hasEssay = false
        plan.monthlyCredits = 0
      }
    })

    plans.sort((a, b) => a.order - b.order)
  }

  return (
    <Container id='planos' isBlackFriday={isBlackFriday}>
      <Wrapper>
        <Title size='sm' color='white'>
          Tenha acesso a uma preparação completa e de alto nível para:
        </Title>
        <Title size='sm' color='white'>
          {titleItems.map((item, itemIndex) => (
            <span key={itemIndex}>
              {itemIndex !== 0 &&
                <span
                  style={{
                    color: !isBlackFriday && accentColor,
                    textShadow:
                      isBlackFriday
                        ? `0 0 5px ${theme.blackFridayTheme.neon},
                           0 0 5px ${theme.blackFridayTheme.neon},
                           0 0 5px ${theme.blackFridayTheme.neon},
                           0 0 5px ${theme.blackFridayTheme.neon}`
                        : 'none',
                  }}
                >
                  +
                </span>}{' '}
              {item}{' '}
            </span>
          ))}
        </Title>

        <Title
          size='xs'
          color='white'
          style={{ fontWeight: 'normal', marginTop: '2rem' }}
        >
          Planos de acesso com todas as matérias, correção de redação e o&nbsp;
          <InnerText isBlackFriday={isBlackFriday}>
            melhor custo-benefício do Brasil.
          </InnerText>
        </Title>

        <PlansBox>
          {plans &&
            plans.map((plan) => (
              <PlanCard
                key={plan.id}
                plan={plan}
                planCount={plans.length}
                blackDays={isBlackFriday}
                utm={utm}
              />
            ))}
        </PlansBox>

        <Warranty>
          <img src='/garantia.png' alt='Garantia de 7 dias' />
        </Warranty>
      </Wrapper>
    </Container>
  )
}

Plans.propTypes = {
  plans: t.array,
  utm: t.string,
}

const Container = styled(BaseContainer)`
  background-color: ${({ theme, isBlackFriday }) =>
    isBlackFriday ? theme.blackFridayTheme.background : 'rgb(0, 17, 94)'};
  max-width: 100%;
`

const Wrapper = styled.section`
  border-radius: ${({ theme }) => theme.radius.lg};
  padding: 5rem 1.5rem 0;

  ${media.greaterThan('lg')`
    padding: 9rem 10rem 0;
  `}
`

const PlansBox = styled.div`
  padding-top: 7rem;
  display: flex;
  justify-content: center;
  gap: 2rem;

  ${media.lessThan('md')`
    display: block;
    padding-top: 7rem;
  `}
`

const Warranty = styled.div`
  padding: 5rem 0;
  text-align: center;

  img {
    display: inline-block;
    max-width: 16rem;

    ${media.lessThan('sm')`
      max-width: 13rem;
    `}
  }
`

const InnerText = styled.b`
  /* color: ${({ theme }) => theme.radius.lg}; */
  text-shadow: ${({ theme, isBlackFriday }) =>
    isBlackFriday
      ? `0 0 5px ${theme.blackFridayTheme.neon},
         0 0 5px ${theme.blackFridayTheme.neon},
         0 0 5px ${theme.blackFridayTheme.neon},
         0 0 5px ${theme.blackFridayTheme.neon}`
      : 'none'};

`
