import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { isVideo } from 'resources/helpers/media-helper.js'
import { media, useWindowSize } from 'resources'

const MOBILE_WIDTH_BREAKPOINT = 950

export const Hero = ({ banners, utm }) => {
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_BREAKPOINT

  const renderMedia = (media) => {
    if (isVideo(media.image)) {
      const videoUrl =
        isMobile && media.imageMobile ? media.imageMobile : media.image

      return (
        <video autoPlay muted playsInline loop>
          <source src={videoUrl} />
          {media.alt && <p>{media.alt}</p>}
        </video>
      )
    }

    return (
      <picture>
        {media.imageMobile && (
          <source
            srcSet={media.imageMobile}
            alt={media.alt}
            media={`(max-width: ${MOBILE_WIDTH_BREAKPOINT}px)`}
          />
        )}

        <img src={media.image} alt={media.alt} />
      </picture>
    )
  }

  const renderInnerBannerContent = (banner) => {
    return (
      <>
        {renderMedia(banner)}
        <RawHtml dangerouslySetInnerHTML={{ __html: banner.rawHtml }} />
      </>
    )
  }

  const renderBanners = () => {
    return banners && banners.map((banner, bannerIndex) => {
      let newLink
      if (banner.firstElement.link) {
        const [baseLink, query] = banner.firstElement.link.split('?')
        const removeQuestionMark = (str) => str.startsWith('?') ? str.slice(1) : str
        const newQuery = query ? `?${utm ? removeQuestionMark(utm) : ''}field_action=assinatura_banner&` + query : `${utm ? removeQuestionMark(utm) : ''}&field_action=assinatura_banner`
        newLink = `${baseLink}${newQuery}`
      }
      return (
        <>
          <Banner key={bannerIndex} bannerIndex={bannerIndex}>
            <BannerLink href={newLink}>
              {renderInnerBannerContent(banner.firstElement)}
            </BannerLink>
          </Banner>
          <Banner key={bannerIndex + 1} bannerIndex={bannerIndex + 1}>
            <BannerBlock>{renderInnerBannerContent(banner.secondElement)}</BannerBlock>
          </Banner>
        </>
      )
    })
  }

  return <Wrapper>{renderBanners()}</Wrapper>
}

Hero.propTypes = {
  banners: t.array,
  utm: t.string,
}

const Wrapper = styled.header`
  max-height: 80vh;
  display: flex;

  @media (max-width: 950px) {
    display: block;
    height: auto;
    max-height: 100%;
  };
`

const Banner = styled.div`
  background-color: #001a85;
  flex: ${({ bannerIndex }) => (bannerIndex === 0 ? 40 : 60)};

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`

const BannerBlock = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  color: unset;
`

const BannerLink = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  color: unset;
`

const RawHtml = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${media.lessThan('sm')`
    width: 100%;
    height: 100%;
    padding: 1rem;

    iframe {
      width: 100%;
      height: 100%;
    }
  `}
`
