import styled, { css } from 'styled-components'
import styledBy from 'styled-by'

import { colors, theme } from 'resources'

const defaultTitle = css`
  font-size: clamp(2.5rem, 5vw, 4.8rem);
  max-width: 70rem;
  line-height: 1.4;
  margin: 0 auto;
  padding: 1.6rem 0;
`

const smallTitle = css`
  font-size: clamp(1.7rem, 3vw, 2.6rem);
  line-height: 1.5;
`

export const Title = styled.h2`
  font-weight: bold;

  ${styledBy('size', {
    default: () => defaultTitle,
    sm: () => smallTitle,
  })}

  ${styledBy('align', {
    center: 'text-align: center;',
    left: 'text-align: left; margin: 0;',
  })}

  ${styledBy('color', {
    default: `color: ${colors.text}`,
    white: `color: ${colors.background}`,
    blue: `color: ${colors.primary}`,
    blackFriday: `color: ${theme.blackFridayTheme.textLight}`,
  })}
`

Title.defaultProps = {
  size: 'default',
  align: 'center',
  color: 'default',
}
