import React from 'react'
import styled, { css } from 'styled-components'

import { Container, Title, Text } from 'components'
import { media } from 'resources'

export const AppMobile = () => {
  return (
    <Container>
      <Wrapper>
        <Content>

          <ContentInner>
            <Title align='left' size='sm' color='blue'>
              Aplicativo do Professor Ferretto
            </Title>
            <Title as='h3' align='left'>Agora temos aplicativo da plataforma</Title>
            <Text align='left'>
              Você pode estudar de uma forma muito mais rápida pelo celular: assista às aulas enquanto volta do trabalho, no ônibus, no mêtro ou no conforto da sua casa.
            </Text>

            <DownloadArea>
              <DownloadItem>
                <DownloadTitle>Android</DownloadTitle>
                <DownloadLink href='#' title='Baixar aplicativo na Play Store'>
                  <img src='/play-store.png' alt='Play Store' />
                </DownloadLink>
              </DownloadItem>
              <DownloadItem>
                <DownloadTitle>iPhone (em breve)</DownloadTitle>
                <DownloadLink href='#' disabled>
                  <img src='/app-store.png' alt='App Store' />
                </DownloadLink>
              </DownloadItem>
            </DownloadArea>
          </ContentInner>

        </Content>
        <Preview>
          <img src='/app-mockup.jpg' alt='Aplicativo do Ferretto' />
        </Preview>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.section`
  margin: 8rem 0 0;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${media.greaterThan('sm')`
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    gap: 4rem;
    align-items: center;
  `}
`

const Content = styled.div`
  max-width: 63.5rem;
  margin: 0 auto;

  ${media.lessThan('sm')`
    margin-bottom: 5rem;

    &,
    > * {
      text-align: center;
    }
  `}
`

const ContentInner = styled.div`
  margin-top: -5rem;

  ${media.lessThan('sm')`
    margin-top: 0;

    > * {
      text-align: center;
    }
  `}
`

const DownloadArea = styled.div`
  display: flex;
  padding-top: 3rem;

  ${media.lessThan('sm')`
    justify-content: center;
  `}
`

const DownloadItem = styled.div`
  padding-right: 1rem;

  ${media.lessThan('sm')`
    padding-right: .5rem;
    padding-left: .5rem;
  `}
`

const DownloadTitle = styled.h6`
  font-size: 1.1rem;
  color: #555;
  padding-bottom: .8rem;
  text-indent: 2px;
`

const DownloadLink = styled.a`
  text-decoration: none;

  img {
    max-height: 4.5rem;
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: .2;
    filter: grayscale(100%);
  `}
`

const Preview = styled.div`
  position: relative;
  margin-top: 4rem;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: -5px;
  }

  ${media.lessThan('sm')`
    text-align: center;
    margin-top: 0;

    img {
      max-width: 80%;
      display: inline-block;
      margin-right: -12%;
    }
  `}
`
