const VIDEO_EXTENSIONS = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'wmv',
  'mov',
  'qt',
  'flv',
  'swf',
  'avchd',
]

export const isVideo = (fileUrl) => {
  const url = new URL(fileUrl)
  const extension = url.pathname.split('.').pop()
  return VIDEO_EXTENSIONS.includes(extension)
}
