export const blackFridayTheme = {
  bodyBackground: '#323232',
  primary: 'rgb(239, 64, 75)',
  text: '#F7F7F7',
  textDark: '#141414',
  secondary: '#1E1E1E',
  background: '#000',
  textRed: '#ef404b',
  divider: '#2D2D2D',
  textLight: '#FFFFFF',
  backgroundPurple: 'rgb(141, 40, 255)',
  neon: 'rgb(207 47 244)',
}

export const colors = {
  primary: '#0049ff',
  secondary: '#eef5ff',
  third: '#0B3D7F',

  success: '#00d992',
  warning: '#EDE847',
  error: '#d93838',

  background: '#fff',
  text: '#000',
  muted: 'rgb(138, 138, 138)',

  accent: 'rgb(0, 235, 169)',
  blackFridayAccent: 'rgb(239, 64, 75)',
  divider: '#e9eeff',
}

export const radius = {
  md: '1rem',
  lg: '2rem',
  xl: '3rem',
}

export const theme = {
  colors,
  radius,
  lighten: 0.33,
  blackFridayTheme,
}
