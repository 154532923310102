import React, { useState } from 'react'
import t from 'prop-types'
import styled, { css } from 'styled-components'
import { theme } from 'resources'

export const Question = ({ title, content, isBlackFriday }) => {
  const [showContent, setShowContent] = useState(false)

  const titleOpenColor = isBlackFriday
    ? theme.blackFridayTheme.neon
    : theme.colors.primary

  return (
    <Wrapper
      onClick={() => setShowContent(!showContent)}
      active={showContent}
      isBlackFriday={isBlackFriday}
    >
      <Title
        isBlackFriday={isBlackFriday}
        style={{ color: showContent ? titleOpenColor : '' }}
      >
        {title}
      </Title>

      {showContent && (
        <ContentWrapper>
          <Content
            isBlackFriday={isBlackFriday}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

Question.propTypes = {
  title: t.string.isRequired,
  content: t.string.isRequired,
  isBlackFriday: t.bool,
}

const Wrapper = styled.div`
  position: relative;
  padding: 2.5rem 5rem 2.5rem 4rem;
  border-radius: ${({ theme }) => theme.radius.md};
  cursor: pointer;
  transition: box-shadow 0.2s ease, border-color 0.2s ease;

  border: 1px solid
    ${({ active, isBlackFriday }) =>
      isBlackFriday
        ? theme.blackFridayTheme.bodyBackground
        : 'rgba(230, 230, 230, 0.7)'};

  background-color: ${({ active, isBlackFriday }) =>
    active
      ? isBlackFriday
      ? theme.blackFridayTheme.background
        : 'white'
      : 'transparent'};

  &:after {
    content: '';
    position: absolute;
    top: 3.4rem;
    right: 3rem;
    display: block;
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid
      ${({ theme, isBlackFriday }) =>
  isBlackFriday ? theme.blackFridayTheme.neon : theme.colors.third};
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  }

  ${({ active }) =>
    active &&
    css`
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;

      &:after {
        top: 3.3rem;
        transform: rotate(180deg);
      }
    `}
`

const Title = styled.h3`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  color: ${({ theme, isBlackFriday }) =>
    isBlackFriday ? theme.blackFridayTheme.text : theme.colors.third};
`

const ContentWrapper = styled.div`
  padding: 2rem 0 1rem;
`

const Content = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 1.6;
  color: ${({ theme, isBlackFriday }) =>
    isBlackFriday ? theme.blackFridayTheme.text : theme.colors.text};
  text-align: left;
  word-break: break-word;
`
